<template>
  <div>
    <div class="flex ">
      <div class="mrr20" v-for="(item,index) in buttonList" :key="index">
        <el-button @click="searchHeader(item)">{{item.name}}({{item.value}})</el-button>
      </div>
    </div>
    <div class="inquire_box mrt20 mrb20">
      <div class="mrb20">筛选查询</div>
      <div>
        <el-form ref="searchForm" :model="searchForm" label-width="100px" class="flex flex-wrap ">
          <el-form-item label="商品名称">
            <el-input v-model="searchForm.name" placeholder="请输入商品名称"></el-input>
          </el-form-item>
          <el-form-item label="状态">
            <el-select v-model="searchForm.status" placeholder="请选择状态" clearable filterable>
              <el-option v-for="item in  statusOption" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="品牌">
            <el-select v-model="searchForm.brand_id" placeholder="请选择商品品牌" @visible-change="getBrand()" clearable filterable>
              <el-option v-for="(item,i) in brand" :key="i" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="品牌系列">
            <el-select v-model="searchForm.brand_series_id" placeholder="请选择商品品牌系列" @visible-change="getBrandList()" clearable filterable>
              <el-option v-for="(item,i) in animebrand" :key="i" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="作者">
            <el-select v-model="searchForm.author_id" placeholder="请选择商品作者" clearable filterable>
              <el-option v-for="(item,i) in Author" :key="i" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="原著">
            <el-select v-model="searchForm.original_id" placeholder="请选择商品原著" clearable filterable>
              <el-option v-for="(item,i) in original" :key="i" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="人物">
            <el-select v-model="searchForm.ip_id" placeholder="请选择商品人物IP" @visible-change="getFigure()" clearable filterable>
              <el-option v-for="(item,i) in figure" :key="i" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="分类">
            <el-select v-model="searchForm.category_id" placeholder="请选择商品分类" @visible-change="getClassify()" clearable filterable>
              <el-option v-for="(item,i) in classify" :key="i" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="onSubmit">查找</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <commodity-list :searchForm="searchForm" ref="commodity"></commodity-list>
    <!--  -->
  </div>
</template>

<script>
// import commodityList from "./commodityList.vue";

import { statusDetail, statusOption } from "@/utils/statusData";
import commodityList from "./commodityList";
export default {
  components: {
    commodityList,
  },
  props: {},
  data() {
    return {
      statusDetail,
      statusOption,
      buttonList: [],
      searchForm: {
        name: "",
        header_id: "",
        status: "",
        brand_id: "",
        brand_series_id: "",
        author_id: "",
        original_id: "",
        ip_id: "",
        category_id: "",
      },
      pageInfo: {
        limit: 1000000,
        page: 1,
        count: 0,
        pageNum: 0,
      },
      animebrand: [],
      brand: [],
      Author: [],
      original: [],
      figure: [],
      classify: [],
      active: "1",
    };
  },

  created() {
    // this.getBrand();
    // this.getBrandList();
    this.getAuthor();
    this.getOriginal();
    this.getHeader();
    // this.getFigure();
    // this.getClassify();
  },
  mounted() {},
  computed: {},

  methods: {
    async getHeader() {
      const { data } = await this.$Api.animeGoodsInit_header();
      this.buttonList = data;
    },

    searchHeader(item) {
      this.searchForm.header_id = item.id;
      this.$refs.commodity.getList();
    },
    // 品牌
    async getBrand() {
      const { data } = await this.$Api.animeBrand(this.pageInfo);
      this.brand = data.list;
    },
    // 品牌系列
    async getBrandList() {
      const { data } = await this.$Api.animebrandSeries(this.pageInfo);
      this.animebrand = data.list;
    },
    // 作者
    async getOriginal() {
      const { data } = await this.$Api.animeAuthor(this.pageInfo);
      this.Author = data.list;
    },
    // 原著
    async getAuthor() {
      const { data } = await this.$Api.originalList(this.pageInfo);
      this.original = data.list;
    },
    // 人物
    async getFigure() {
      const { data } = await this.$Api.animeIP(this.pageInfo);
      this.figure = data.list;
    },
    // 分类
    async getClassify() {
      const { data } = await this.$Api.animeCategory(this.pageInfo);
      this.classify = data.list;
    },
    onSubmit() {
      this.$refs.commodity.getList();
    },
  },
};
</script>
<style lang='scss' scoped>
.inquire_box {
  ::v-deep .el-input__inner {
    width: 200px;
  }
}

.inquire_box {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 20px 20px 40px 20px;
  background-color: #ffffff;
}
</style>